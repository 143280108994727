"use client"

import { fallbackLocale } from "@/i18n"

import "@/app/globals.css"
import dynamic from "next/dynamic"

const GlobalError = dynamic(() => import("./error"))
const AppProvider = dynamic(() => import("./_providers/app"))

interface GlobalErrorProps {
  error: Error & { digest?: string }
  reset: () => void
}

export default function GlobalErrorPage({ error, reset }: GlobalErrorProps) {
  const defaultLocale = fallbackLocale

  return (
    <html lang={defaultLocale}>
      <head>
        {/**
         * as of now, loading next/fonts is a known limitation at turbopack, we will use the global font for now
         * https://nextjs.org/docs/messages/no-page-custom-font#possible-ways-to-fix-it
         */}
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=optional"
          rel="stylesheet"
        />
      </head>
      <body id="root">
        <AppProvider>
          <GlobalError error={error} reset={reset} />
        </AppProvider>
      </body>
    </html>
  )
}
